import React, {Component} from "react";

import AboutUs from '../Assets/YW1.png'; 
import OmanIndo from '../Assets/OmanIndo.png';
import Image1 from '../Assets/news-image.png';
import Image2 from '../Assets/Image2.png';
import Image3 from '../Assets/Image3.png';
import Image4 from '../Assets/Image4.png';
import WahidLogo from '../Assets/wahid.png';
import Peace from '../Assets/peace.png';
import TAwadah from '../Assets/tokoawadah.png';
import Dakwah from '../Assets/dakwahlogo.png';
import Wir from '../Assets/wir.png';
import Bg from '../Assets/Background.png';
import Bg1 from '../Assets/Background1.png';
import Bg2 from '../Assets/Background2.png';
import Bg3 from '../Assets/Background3.png';
import Divider from '../Assets/devider section.png';
import Peace1 from '../Assets/peace1.png';
import Peace2 from '../Assets/peace2.png';
import Peace3 from '../Assets/peace3.png';
import Peace4 from '../Assets/peace4.png';
import TAmobile from '../Assets/awadahmobile.png';
import Dakwahmobile from '../Assets/dakwahmobile.png';
import Arrow from '../Assets/Vector.png';

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

class Home extends Component{

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render(){
        return(
            <div>
                <Navbar />
                <div className="home" id="aboutus" style={{ backgroundImage: `url(${AboutUs})` }}>
                    <div className='container px-0 w-100 about-us'>
                            <p className="section-title">ABOUT US</p>
                            <h1>Inspiring Good Values Through Innovation To Advance Indonesia's Wellbeing</h1>
                            <p className="description">Awadah is a modern nation platform that aims to unite and improve the welfare of the Indonesians. By playing an active role through innovative solutions for the advancement of Indonesia.</p>
                    </div>
                </div>
                <div className="bg-mobile-con">
                    <img src={AboutUs} className="bg-mobile" alt="aboutus"/>
                </div>
                <img src={Divider} alt="divider" className="divider"/>
                <div className="news-event" id="news">
                    <div className="container px-0 news-cont">
                        <p className="section-title">NEWS & EVENT</p>
                        <div className="white-box row m-0" >
                            <div className="col-md">
                                <img src={OmanIndo} alt="OmanIndo" />
                                <h3>Collaboration of Oman and Indonesia Develops Linguistic Platform Based On AR & AI Through ETCO & Awadah Group</h3>
                                <p>The Sultanates of Oman and Indonesia have formed a partnership to develop a linguistic platform based on Augmented Reality (AR) and Artificial Intelligence (AI) technology. This form of partnership is supported by the Minister of Waqf and Religion H.E Shaikh Abdullah bin Mohammed Al Salimi through the Emerging Technology Company of Oman (ETCO) and Awadah Group Indonesia. The inauguration of this partnership was marked by the signing of a memorandum of understanding represented by CEO Abdulaziz Jafaar from ETCO, and President Director Dhohir Farisi from Awadah Group Indonesia.</p>
                            </div>
                            <div className="col-md">
                                <img src={Image2} className="Image-News" alt="Image2"/>
                                <img src={Image1} className="Image-News" alt="Image1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="our-value" id="ourvalue" style={{ backgroundImage: `url(${Bg})` }}>
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-md our-val">
                                <p className="section-title">OUR VALUE</p>
                                <h2>Awadah Present for the Nation's Welfare</h2>
                                <p>Driven by our mission to always unite the Indonesian nation and our vision for innovation, our aim is to encourage communities as a force that can advance common prosperity.</p>
                            </div>
                            <div className="col-md image-val-con">
                               <img src={Image4} alt="Image4" className="shadow" />
                               <img src={Image3} alt="Image3" className="shadow" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="networks" id="networks" style={{ backgroundImage: `url(${Bg1})` }}>
                    <div className="container px-0">
                        <p className="section-title">NETWORKS & UNITS</p>
                        <div className="net-con row-mob">
                            <div className="net-word">
                                <h2>Networks</h2>
                            </div>
                            <div className="white-box">
                                <img src={WahidLogo} alt="WahidLogo"/> 
                            </div>
                            <div className="white-box">
                                <img src={Wir} alt="WahidLogo"/> 
                            </div>
                        </div>
                        <div className="net-con row-mob">
                            <div className="net-word">
                                <h2>Units</h2>
                            </div>
                            <div className="white-box">
                                <img src={Peace} alt="WahidLogo" className="peace-logo"/> 
                            </div>
                            <div className="white-box">
                                <img src={TAwadah} alt="WahidLogo"/> 
                            </div>
                            <div className="white-box">
                                <img src={Dakwah} alt="WahidLogo"/> 
                            </div>
                        </div>
                        {/* mobile view start */}
                        <div className="net-unit-mobile">
                            <h2>Networks</h2>
                            <div className="net-unit-con">
                                <div className="white-box">
                                    <img src={WahidLogo} alt="WahidLogo"/> 
                                </div>
                                <div className="white-box">
                                    <img src={Wir} alt="WahidLogo"/> 
                                </div>
                            </div>
                            <h2>Units</h2>
                            <div className="net-unit-con">
                                <div className="white-box">
                                    <img src={Peace} alt="WahidLogo" className="peace-logo"/> 
                                </div>
                                <div className="white-box">
                                    <img src={TAwadah} alt="WahidLogo"/> 
                                </div>
                                <div className="white-box">
                                    <img src={Dakwah} alt="WahidLogo"/> 
                                </div>
                            </div>
                        </div>
                        {/* mobile view end */}
                    </div>
                </div>
                <div className="peace" id="peace">
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-md our-val px-0">
                                <div className="peace-sec">
                                    <img src={Peace} className="peace-logo" alt="peace-logo"/>
                                    <p className="section-title">PEACE VILLAGE</p>
                                </div>
                                <h2>The First Augmented Reality Park in Indonesia with Good Values</h2>
                                <p>Peace Village initiative aims to tackle the threat of radicalism by empowering communities, one village at a time. By the end of 2019, Awadah Group has launched a new model of Peace Village in Sleman area of ​​Yogyakarta. The park which is supported by Augmented Reality technology with an area of ​​5,000 square meters invites visitors to learn interactively with the edutainment concept that can educate the values ​​of diversity and tolerance as well as other good values.</p>
                            </div>
                            <div className="col-md image-val-con px-0">
                                <div className="image-peace-con">
                                    <img src={Peace1} alt="Image4" className="shadow" />
                                    <img src={Peace2} alt="Image3" className="shadow" />
                                </div>
                                <div className="image-peace-con">
                                    <img src={Peace3} alt="Image4" className="shadow" />
                                    <img src={Peace4} alt="Image3" className="shadow" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tokoawadah"id="tokoawadah" style={{ backgroundImage: `url(${Bg2})` }}>
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-md our-val px-0">
                                <div className="peace-sec">
                                    <img src={TAwadah} className="peace-logo" alt="peace-logo"/>
                                </div>
                                <h2>Digital Store For Indonesians Without Large Capitals</h2>
                                <p>Toko Awadah exists as a digital platform to help prosperity by empowering Indonesians to become Entrepreneurs. Toko Awadah has various beneficial advantages, including thousands of halal-certified products, umkm and banking products, complete bill payments, each member benefits from sales transactions that can be made anytime and anywhere, and is supported by an easy payment process.</p>
                                <a href="https://tokoawadah.co.id/">
                                    <button className="button-find" type="button">Find Out More<img src={Arrow} className="arrow-vec" alt="arrow"/></button>
                                </a>
                            </div>
                            <div className="col-md px-0 image-ta-con">
                                <img src={TAmobile} alt="Image4" className="ta-logo"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dakwah" id="dakwahawadah" style={{ backgroundImage: `url(${Bg3})` }}>
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-md our-val px-0">
                                <div className="peace-sec">
                                    <img src={Dakwah} className="peace-logo" alt="peace-logo"/>
                                </div>
                                <h2>An Online Religious Learning Platform That Can Be Accessed From Anywhere</h2>
                                <p>Awadah Dakwah is an online platform that presents online studies from ustadz and scholars that can be accessed through the Cakap application. This solution is presented so that the Indonesians can study with ustadz and scholars anytime and anywhere. The public can interact directly with the speaker via live video and chat features during the broadcast.</p>
                                <a href="https://tokoawadah.co.id/dakwah">
                                    <button className="button-find" type="button">Find Out More<img src={Arrow} className="arrow-vec" alt="arrow"/></button>
                                </a>
                            </div>
                            <div className="col-md px-0 image-ta-con">
                                <img src={Dakwahmobile} alt="Image4" className="dakwah-logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Home;