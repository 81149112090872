import React, {Component} from "react";

import WahidLogo from '../Assets/wahid.png';
import Wir from '../Assets/wir.png';
import AwadahGrp from '../Assets/LogoAwadahGrp.png';
import Youtube from '../Assets/Vector 2.png';
import Mail from '../Assets/mail.png';

class Footer extends Component {
    render(){
        return(
            <div className="container-fluid footer">
                <div className="row row-mob">
                    <div className="col-md px-0">
                        <h4>Contact Us</h4>
                        <div className="youtube">
                            <img src={Mail} alt="youtube" className="icons"/>
                            <p className="info-desc">halo@awadahgroup.com</p>
                        </div>
                        <img src={AwadahGrp} alt="awadah-group" className="logo-awadahgrup"/>
                    </div>
                    <div className="col-md px-0">
                        <h4>Follow Our Channel</h4>
                        <div className="youtube">
                            <img src={Youtube} alt="youtube" className="icons"/>
                            <a href="https://www.youtube.com/channel/UC9KyNKJiDlGsTrEnlhhSPEw">
                                <p className="info-desc">Awadah Channel</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-md px-0">
                        <div className="wir-wahid">
                            <div className="power">
                                <h4>Powered by</h4>
                                <img src={Wir} alt="wir-logo" className="wir-logo"/>
                            </div>
                            <div className="partner">
                                <h4>In partnership with</h4>
                                <img src={WahidLogo} alt="wahid-logo" className="wahid-logo"/>
                            </div>
                        </div>
                        <p className="copyright">Copyright 2020 © Awadah Group. All Right Reserved</p>
                    </div>
                </div>

                <div className="footer-mob">
                    <h4>Contact Us</h4>
                    <div className="youtube">
                        <img src={Mail} alt="youtube" className="icons"/>
                        <p className="info-desc">halo@awadahgroup.com</p>
                    </div>
                    <h4>Follow Our Channel</h4>
                    <div className="youtube">
                        <img src={Youtube} alt="youtube" className="icons"/>
                        <a href="https://www.youtube.com/channel/UC9KyNKJiDlGsTrEnlhhSPEw">
                            <p className="info-desc">Awadah Channel</p>
                        </a>
                    </div>
                    <div className="wir-wahid">
                            <div className="power">
                                <h4>Powered by</h4>
                                <img src={Wir} alt="wir-logo" className="wir-logo"/>
                            </div>
                            <div className="partner">
                                <h4>In partnership with</h4>
                                <img src={WahidLogo} alt="wahid-logo" className="wahid-logo"/>
                            </div>
                            <img src={AwadahGrp} alt="awadah-group" className="logo-awadahgrup"/>
                        </div>
                        <p className="copyright">Copyright 2020 © Awadah Group. All Right Reserved</p>
                </div>
            </div>
        )
    }
}
export default Footer;