import React, {Component} from "react";
import AwadahGroupLogo from "../Assets/LogoAwadahGrp.png";
import Close from '../Assets/close.png';
// import Slide from 'react-reveal/Slide';
import ScrollspyNav from "react-scrollspy-nav";

class Navbar extends Component{

    state = {
        nav: false,
        activeBar: 'beranda'
    }

    handleClick = () => {
        this.setState({
            nav: !this.state.nav
        });
    }

    render(){

        if (this.state.nav) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return(
            <div>
                 <ScrollspyNav
                    scrollTargetIds={["aboutus", "news", "ourvalue", "networks", "peace", "tokoawadah", "dakwahawadah"]}
                    headerBackground="true"
                    activeNavClass="is-active"
                    scrollDuration="10"
                >
                    <header className='fixed-top'>
                        <nav className="container-fluid navbar">
                            <img className="logo-AwadahGroup" src={AwadahGroupLogo} alt="logo-awadahgroup" />
                            <ul>
                                <li className="link-right">
                                    <a href="#dakwahawadah">
                                    Dakwah Awadah
                                    </a>
                                </li>
                                <li className="link-right">
                                    <a href="#tokoawadah">
                                    Toko Awadah
                                    </a>
                                </li>
                                <li className="link-right">
                                    <a href="#peace">
                                    Peace Village
                                    </a>
                                </li>
                                <li className="link-right ">
                                    <a href="#networks">
                                    Networks & Unit
                                    </a>
                                </li> 
                                <li className="link-right">
                                    <a href="#ourvalue">
                                    Our Value
                                    </a>
                                </li> 
                                <li className="link-right">
                                    <a href="#news">
                                    News & Event
                                    </a>
                                </li> 
                                <li className="link-right">
                                    <a href="#aboutus" className="is-active">
                                        About Us
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </header>
                </ScrollspyNav>

                {/* mobile view start */}

                {/* <Slide top collapse when={this.state.nav}>
                </Slide> */}
                <div className="mobile-show">
                    <nav className='container-fluid nav-mobile'>
                            <img className="logo-AwadahGroup" src={AwadahGroupLogo} alt="logo-awadahgroup" />
                            <div className='burger' onClick={this.handleClick}>
                                <div className='line'></div>
                                <div className='line'></div>
                                <div className='line'></div>
                            </div>
                    </nav>
                </div>
                <div className={this.state.nav ? 'header fixed-top' : 'header-none'}>
                    <nav className='container p-0 navbar-overlay'>
                        <img className="logo-AwadahGroup" src={AwadahGroupLogo} alt="logo-awadahgroup" />
                        <img
                            className='close-but'
                            src={Close}
                            alt='close-button'
                            onClick={() => this.setState({ nav: false })} />
                    </nav>
                    <ScrollspyNav
                    scrollTargetIds={["aboutus", "news", "ourvalue", "networks", "peace", "tokoawadah", "dakwahawadah"]}
                    headerBackground="true"
                    activeNavClass="is-active"
                    scrollDuration="10"
                    >
                    <div className='menu-lay'>
                        <div className='body'>
                            <div className="menu-con">
                                <a href="#aboutus">
                                    <p onClick={this.handleClick}>About Us</p>
                                </a>
                            </div>
                            <div className="menu-con">
                                <a href="#news">
                                    <p onClick={this.handleClick}>News & Event</p>
                                </a>
                            </div>
                            <div className="menu-con">
                                <a href="#ourvalue">
                                    <p onClick={this.handleClick}>Our Value</p>
                                </a>
                            </div> 
                            <div className="menu-con">
                                <a href="#networks">
                                    <p onClick={this.handleClick}>Networks & Unit</p>
                                </a>
                            </div>
                            <div className="menu-con">
                                <a href="#peace">
                                    <p onClick={this.handleClick}>Peace Village</p>
                                </a>
                            </div>
                            <div className="menu-con">
                                <a href="#tokoawadah">
                                    <p onClick={this.handleClick}>Toko Awadah</p>
                                </a>
                            </div>
                            <div className="menu-con">
                                <a href="#dakwahawadah">
                                    <p onClick={this.handleClick}>Awadah Dakwah</p>                            
                                </a>
                            </div>
                        </div>
                    </div>
                    </ScrollspyNav>
                </div>
            </div>
        )
    }
}

export default Navbar;